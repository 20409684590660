<style lang="scss">
#debug {
  display: none;
}

#frontend {
  .lehrepraktika-all {
    .header-intro-generel-box {
      background-position: center center !important;
    }

    .bg-image-outer {
      width: 100%;
      position: relative;

      .img-box {
        width: 100%;
        height: 700px;
        background-size: cover;
        background-position: center center;
        overflow: hidden;

        @media(max-width:$width-xl) {
          height: 600px;
        }

        @media(max-width:$width-lg) {
          height: 500px;
        }

        @media(max-width:$width-lg) {
          height: 400px;
        }
      }

      .zitat {
        max-width: 400px;
        font-family: 'American Typewriter ITC W04 Md';
        color: #fff;
        position: absolute;
        right: 20%;
        top: 30%;
        font-size: 150%;
        padding: 30px;
        text-align: center;

        @media(max-width:$width-lg) {
          font-size: 120%;
        }

        .name {
          display: block;
          font-size: 18px;
          font-family: 'Helvetica Neue LT W05 65 Medium';
          text-transform: uppercase;
          letter-spacing: 2px;
          margin-top: 20px;

          .firma {
            display: block;
            font-size: 14px;
            margin-top: 5px;
            font-family: 'Helvetica Neue LT W05 45 Light';
          }
        }

        .zitat-icon {
          display: block;
          margin: 0 auto;
        }

        @media(max-width:$width-xl) {
          right: 10%;
          font-size: 200%;
        }

        @media(max-width:$width-lg) {
          right: 10%;
          font-size: 150%;
          right: 0;
          position: relative;
          color: #000;
          margin: 20px auto;
          text-align: center;
        }
      }

      .zitat-viviane-kogler {
        color: #fff;
        background-color: rgba($color: $coral, $alpha: .8);

        @media(max-width:$width-lg) {
          //background:none;
        }

        .zitat-icon {
          fill: #fff;

        }
      }



      .zitat-sascha-uhl {
        left: 20%;
        right: 0;
        color: #000;
        background-color: rgba($color: $violet-50per, $alpha: .8);

        @media(max-width:$width-lg) {
          //background:none;
          left: 0;
        }
      }


    }

    h2 {
      text-align: left;
      margin: 60px 0;
      font-family: 'Helvetica Neue LT W05 96 Blk It';

      .breaker {
        display: none;

        @media(max-width:$width-md) {
          display: block;
          height: 1px;
        }
      }

      .breaker-sign {
        display: none;

        @media(max-width:$width-md) {
          display: inline-block;
        }
      }

    }

    h3 {
      font-family: 'Helvetica Neue LT W05_75 Bold';
      text-transform: none;
      font-size: 140%;
    }

    h4 {
      font-family: 'Helvetica Neue LT W05 56 Italic';
      margin-bottom: 30px;
      text-transform: none;
      font-size: 140%;
    }


    .img-txt-box-outer {
      position: relative;
      margin-bottom: 25px;

      .img-box {
        width: 100%;
        background-size: cover;
        background-position: center center;
        position: relative;
        padding: 25px;

        @media(max-width:$width-lg) {
          background-image: none !important;
        }

        img {
          display: none;

          @media(max-width:$width-lg) {
            display: block;
          }
        }
      }

      .img-txt-outer {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        text-align: center;
        //background:#fff;
        transition: $transition-std;
        opacity: 0;
        padding: 25px;

        @media(max-width:$width-lg) {
          opacity: 1;
          position: relative;
          top: 0;


        }

        .img-txt-inner-outer {
          border: 1px solid #fff;
          background: #fff;
          position: relative;
          height: 100%;
          width: 100%;
        }

        .img-txt-inner {
          position: relative;
          top: 50%;
          transform: translateY(-70%);
          max-width: 70%;
          margin: 0px auto;

          @media(max-width:$width-lg) {
            max-width: 100%;
            top: 0;
            transform: translateY(0%);
            margin: 40px auto;
          }

          @media(max-width:$width-sm) {
            margin: 0 auto 40px auto;
          }


        }

        h2 {
          font-family: 'Helvetica Neue LT W05_75 Bold';
          margin-top: 10px;
          margin-bottom: 20px;

          @media(max-width:$width-sm) {
            font-size: 200%;
          }
        }

        span {
          display: block;
          font-family: 'Helvetica Neue LT W05 35 Thin';
          margin-top: 20px;
          margin-bottom: 40px;
        }

        p {
          font-family: 'Helvetica Neue LT W05 35 Thin';
        }

        .kategorie {
          position: absolute;
          width: 100%;
          bottom: 30px;
          font-size: 90%;
          text-align: center;
          font-family: 'Helvetica Neue LT W05 65 Medium';
          text-transform: uppercase;
          letter-spacing: 2px;

          @media(max-width:$width-lg) {
            position: relative;
            bottom: 0;
            border-bottom: 1px solid #000;
            padding-bottom: 15px;
          }
        }

      }

      &:hover {
        .img-txt-outer {
          opacity: 1;
        }
      }
    }

    .intro-txt {
      p {
        margin-bottom: 0;

        @media(max-width:$width-lg) {
          padding: 20px 15px !important;
        }
      }
    }

    .height-xxl {
      height: 700px;

      @media(max-width:$width-xl) {
        height: 500px;
      }

      @media(max-width:$width-lg) {
        height: auto;
      }
    }

    .person-top-position {
      position: relative;
      top: -50px;

      @media(max-width:$width-lg) {
        top: 0;

      }

    }

    .person-box {
      position: relative;
      margin-top: 150px;

      @media(max-width:$width-lg) {
        margin-top: 100px;
      }


      .zitat-box {

        font-family: 'American Typewriter ITC W04 Md';
        line-height: 1.5;
        text-align: center;
        text-transform: none;
        font-size: 140%;
        position: absolute;
        padding: 30px;
        max-width: 400px;

        @media(max-width:$width-xl) {
          position: relative;
          max-width: 100%;
        }

        .zitat-icon {
          display: block;
          margin: 0 auto;
        }

        .zitat-icon-white {
          fill: #fff;

        }

        .name {
          display: block;
          font-size: 80%;
          font-family: 'Helvetica Neue LT W05 65 Medium';
          text-transform: uppercase;
          letter-spacing: 2px;
          margin-top: 20px;

          .firma {
            display: block;
            font-size: 80%;
            margin-top: 5px;
            font-family: 'Helvetica Neue LT W05 45 Light';
          }
        }
      }

      .zitat-color-fff {
        color: #fff;

        svg {
          fill: #fff;
        }
      }

      .top-left {
        top: 150px;
        left: -100px;

        @media (max-width: $width-xl) {
          top: 0;
          left: 0;
        }
      }

      .top-right {
        top: 150px;
        right: -100px;

        @media (max-width: $width-xl) {
          top: 0;
          right: 0;
        }
      }

      .txt-box {
        text-align: left;
        padding: 60px 15px;
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;

        @media(max-width:$width-lg) {
          max-width: 100%;
          padding: 30px 20px;
        }

        .title {
          font-family: 'Helvetica Neue LT W05 35 Thin';
          letter-spacing: 2px;
          font-size: 200%;
        }

        p {
          strong {
            display: block;
            font-family: 'Helvetica Neue LT W05_75 Bold';
          }
        }
      }

      .box-middle {
        border: 1px solid #878787;
      }
    }
  }
}
</style>

<template>
  <SocialMediaIconBox />
  <div class="lehrepraktika-all">
    <!--<HeaderImageBoxGenerel :style="{ 'background-image': `url(${require('@/assets/img/karriere/header-img-top.jpg')})` }"  headerImageText="Lehre &amp; Praktika" />-->
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="headline-box-std">
            <div class="headline-inner">
              <div class="breadcrumb"><span>{{ $t('General.karriere') }}</span></div>
              <h1 v-html="$t('General.lehre-praktika-v2')"></h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-8">
          <p class="intro-txt text-center padding-t-s padding-b-s">{{ $t('lehre-praktika.intro-txt') }}</p>
        </div>
      </div>
    </div>
    <!-- -->

    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="person-box">
            <img src="@/assets/img/karriere/sascha-uhl-informationstechnologie.jpg" alt="Sascha Uhl - Styria IT Solutions"
              class="img-fluid">
            <div class="zitat-box top-left bg-mint">
              <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('lehre-praktika.sascha-uhl-zitat') }}<span
                class="name">Sascha Uhl <span class="firma">Styria IT Solutions</span></span>
            </div>
            <div class="box-middle">
              <div class="txt-box">

                <h2 v-html="$t('lehre-praktika.informationstechnologie-hl')"></h2>
                <h3>{{ $t('lehre-praktika.informationstechnologie-hl-v1') }}</h3>
                <p>{{ $t('lehre-praktika.informationstechnologie-txt-v1') }}</p>
                <h3>{{ $t('lehre-praktika.informationstechnologie-hl-v2') }}</h3>
                <p>{{ $t('lehre-praktika.informationstechnologie-txt-v2') }}</p>
                <h3>{{ $t('lehre-praktika.informationstechnologie-hl-v3') }}</h3>
                <p>{{ $t('lehre-praktika.informationstechnologie-txt-v3') }}</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12" v-inview="['move-in-from-top']">
          <div class="highlight-txt-end text-center  margin-t-m">
            <p>{{ $t('lehre-praktika.jetzt-durchstarten-txt') }}</p>
            <div class="btn-std">
              <router-link :to="{ name: 'offene-positionen' }"><span class="txt">{{
                $t('lehre-praktika.zu-den-lehrstellen')
              }}</span> <span class="icon-img"><img src="@/assets/img/icon-arrow-right.svg" alt="Pfeil rechts"
                    class="img-fluid" /></span></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- PRAKTIKA -->
    <div class="container margin-t-xl">
      <div class="row">
        <div class="col-12">
          <div class="headline-box-std">
            <div class="headline-inner">
              <div class="breadcrumb"><span>{{ $t('General.karriere') }}</span></div>
              <h1>{{ $t('lehre-praktika.praktika') }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-9 col-md-8">
          <p class="intro-txt text-center padding-b-s">{{ $t('lehre-praktika.praktikum-txt') }}</p>
        </div>
      </div>
    </div>


    <div class="container margin-t-s">
      <div class="row justify-content-center">
        <div class="col-lg-12" v-inview="['move-in-from-top']">
          <div class="highlight-txt-end highlight-boder-mint text-center">
            <p>{{ $t('lehre-praktika.praktikum-txt-v1') }} </p>
            <div class="btn-std">
              <router-link :to="{ name: 'offene-positionen' }"><span class="txt"
                  v-html="$t('lehre-praktika.praktikum-btn')"> </span> <span class="icon-img"><img
                    src="@/assets/img/icon-arrow-right.svg" alt="Pfeil rechts" class="img-fluid" /></span></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- LEHRREADKTION -->

    <div class="container margin-t-xl">
      <div class="row">
        <div class="col-12">
          <div class="headline-box-std">
            <div class="headline-inner">
              <div class="breadcrumb"><span>{{ $t('General.karriere') }}</span></div>
              <h1 v-html="$t('lehre-praktika.lehr-redaktion')"></h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid bg-violet margin-tb-m">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-10">
          <p class="intro-txt text-center  padding-tb-l color-fff">{{ $t('lehre-praktika.lehr-redaktion-txt') }}</p>
        </div>
      </div>
    </div>

    <!-- 
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="person-box person-top-position">
            <img src="XXX" alt="XXXX" class="img-fluid">
            <div class="zitat-box top-right bg-wine-red color-fff">
              <Icon :path="mdiFormatQuoteClose" class="zitat-icon zitat-icon-white" />
              {{ $t('lehre-praktika.jakob-illek-zitat') }}<span class="name">Jakob Illek <span class="firma">Kleine
                  Zeitung</span></span>
            </div>
            <div class="box-middle">
              <div class="txt-box">

                <h3>{{ $t('lehre-praktika.jakob-illek-hl-v1') }} </h3>
                <p>{{ $t('lehre-praktika.jakob-illek-txt-v1') }}</p>
                <h3>{{ $t('lehre-praktika.jakob-illek-hl-v2') }} </h3>
                <p>{{ $t('lehre-praktika.jakob-illek-txt-v2') }}</p>
                <h3>{{ $t('lehre-praktika.jakob-illek-hl-v3') }} </h3>
                <p>{{ $t('lehre-praktika.jakob-illek-txt-v3') }}</p>
                <p></p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    -->

    <!--
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="person-box person-top-position">
            <img src="@/assets/img/karriere/iris-bonavida.jpg" alt="Iris Bonavida - Kleine Zeitung" class="img-fluid">
            <div class="zitat-box top-left bg-blue-60perc ">
              <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{$t('lehre-praktika.iris-ronavida-zitat')}}<span
                class="name">Iris Bonavida <span class="firma">Die Presse</span></span>
            </div>
            <div class="box-middle">
              <div class="txt-box">

                <h3>{{$t('lehre-praktika.iris-ronavida-hl-v1')}} </h3>
                <p>{{$t('lehre-praktika.iris-ronavida-txt-v1')}}</p>
                <h3>{{$t('lehre-praktika.iris-ronavida-hl-v2')}} </h3>
                <p>{{$t('lehre-praktika.iris-ronavida-txt-v2')}}</p>
                <h3>{{$t('lehre-praktika.iris-ronavida-hl-v3')}} </h3>
                <p>{{$t('lehre-praktika.iris-ronavida-txt-v3')}}</p>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    -->
    <div class="container margin-t-s">
      <div class="row justify-content-center">
        <div class="col-lg-10" v-inview="['move-in-from-top']">
          <div class="highlight-txt-end  text-center">
            <p v-html="$t('lehre-praktika.lehrredaktion-txt-v1')"> </p>
            <div class="btn-std">
              <!---<router-link :to="{ name: 'offene-positionen' }"
              ><span class="txt">{{$t('lehre-praktika.lehrredaktion-btn')}} </span> <span class="icon-img"><img src="@/assets/img/icon-arrow-right.svg" alt="Pfeil rechts" class="img-fluid"/></span
            ></router-link>-->
              <!----><a href="https://www.styria.com/de/karriere/traumjob-gesucht" target="_blank"><span class="txt">{{
                $t('lehre-praktika.lehrredaktion-btn')
              }} </span> <span class="icon-img"><img src="@/assets/img/icon-arrow-right.svg" alt="Pfeil rechts"
                    class="img-fluid" /></span></a>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
  <Footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import HeaderImageBoxGenerel from '../../../components/Frontend/HeaderImageBoxGenerel.vue';
import { mdiChevronUp, mdiFormatQuoteClose } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'Praktikum und Lehre | Styria Media Group',
      keywords: ['praktikum', 'lehre', 'lehrstelle', 'ferialjob', 'medienunternehmen', 'styria'],
      description: 'Praktikumsplatz oder Lehrstelle gesucht? Jetzt durchstarten – in einem von vielen Lehrberufen und Praktika in der STYRIA',
      image: require('@/assets/img/og/karriere.png'),

    },
    en: {
      title: 'Internships and apprenticeships | Styria Media Group',
      keywords: ['internship', 'apprenticeship', 'summer job', 'media company', 'styria'],
      description: 'Looking for an internship or apprenticeship? Get started now at STYRIA! ',
      image: require('@/assets/img/og/karriere.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    //HeaderImageBoxGenerel,
    Footer,

  },
  setup() {
    return {
      mdiChevronUp,
      mdiFormatQuoteClose,
    };
  },
});
</script>
